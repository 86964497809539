import React from 'react';
// import cutie from '../../cutie.jpg';
import cutie from '../../cutie.jpg'
import './css/Profile.css';
import linkedin from '../../assets/link-icons/linkedin.svg';
import github from '../../assets/link-icons/github.svg';
import ArcadeGame from '../arcade/ArcadeGames'

function Profile() {
    return (
        <div className="full-profile-container">
            <div className="mid">
                <div className="profile-container">
                    <img src={cutie} className="profile-pic" alt="my ugly mug" />
                    <h1>Philip Bolinao</h1>
                </div>

                <div className="miniGame">
                    <ArcadeGame />
                </div>

                <div className="links-info">
                    <a href="https://github.com/pbolinao" target="_blank" rel="noopener noreferrer">
                        <img src={github} alt="github" />
                    </a>
                    <a href="https://www.linkedin.com/in/pdbolinao/" target="_blank" rel="noopener noreferrer">
                        <img src={linkedin} alt="linkedin" />
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Profile