import React, { Component } from 'react';
import './App.css';
import AboutPage from './pages/AboutPage';
import GamesPage from './pages/GamesPage';
import ProjectsPage from './pages/ProjectsPage';
import ContactPage from './pages/ContactPage';
import Profile from './pages/Profile'

class App extends Component {
  constructor(props){
    super(props);
    this.changeToAbout = this.changeToAbout.bind(this);
    this.changeToGames = this.changeToGames.bind(this);
    this.changeToProjects = this.changeToProjects.bind(this);
    this.changeToContact = this.changeToContact.bind(this);
    this.state = { currPage: 0 }
  }

  changeToAbout() { this.setState({currPage: 0}); }
  changeToGames() { this.setState({currPage: 1}); }
  changeToProjects() { this.setState({currPage: 2}); }
  changeToContact() { this.setState({currPage: 3}); }

  render() {
    const currPage = this.state.currPage;
    let rbox;
    if (currPage === 0) {
      rbox = <AboutPage/>
    } else if (currPage === 1) {
      rbox = <GamesPage />
    } else if (currPage === 2) {
      rbox = <ProjectsPage />
    } else if (currPage === 3) {
      rbox = <ContactPage />
    }

    return (
      <div className="main-container">
        <div className="main">
          <div className="lbar-container">
            <div className="icon-container">
              <svg xmlns="http://www.w3.org/2000/svg" className="lbar-icon" alt="about-me" onClick={this.changeToAbout} viewBox="0 0 24 24" fill="black" ><path d="M0 0h24v24H0V0z" fill="none"/><path d="M17.81 4.47c-.08 0-.16-.02-.23-.06C15.66 3.42 14 3 12.01 3c-1.98 0-3.86.47-5.57 1.41-.24.13-.54.04-.68-.2-.13-.24-.04-.55.2-.68C7.82 2.52 9.86 2 12.01 2c2.13 0 3.99.47 6.03 1.52.25.13.34.43.21.67-.09.18-.26.28-.44.28zM3.5 9.72c-.1 0-.2-.03-.29-.09-.23-.16-.28-.47-.12-.7.99-1.4 2.25-2.5 3.75-3.27C9.98 4.04 14 4.03 17.15 5.65c1.5.77 2.76 1.86 3.75 3.25.16.22.11.54-.12.7-.23.16-.54.11-.7-.12-.9-1.26-2.04-2.25-3.39-2.94-2.87-1.47-6.54-1.47-9.4.01-1.36.7-2.5 1.7-3.4 2.96-.08.14-.23.21-.39.21zm6.25 12.07c-.13 0-.26-.05-.35-.15-.87-.87-1.34-1.43-2.01-2.64-.69-1.23-1.05-2.73-1.05-4.34 0-2.97 2.54-5.39 5.66-5.39s5.66 2.42 5.66 5.39c0 .28-.22.5-.5.5s-.5-.22-.5-.5c0-2.42-2.09-4.39-4.66-4.39s-4.66 1.97-4.66 4.39c0 1.44.32 2.77.93 3.85.64 1.15 1.08 1.64 1.85 2.42.19.2.19.51 0 .71-.11.1-.24.15-.37.15zm7.17-1.85c-1.19 0-2.24-.3-3.1-.89-1.49-1.01-2.38-2.65-2.38-4.39 0-.28.22-.5.5-.5s.5.22.5.5c0 1.41.72 2.74 1.94 3.56.71.48 1.54.71 2.54.71.24 0 .64-.03 1.04-.1.27-.05.53.13.58.41.05.27-.13.53-.41.58-.57.11-1.07.12-1.21.12zM14.91 22c-.04 0-.09-.01-.13-.02-1.59-.44-2.63-1.03-3.72-2.1-1.4-1.39-2.17-3.24-2.17-5.22 0-1.62 1.38-2.94 3.08-2.94s3.08 1.32 3.08 2.94c0 1.07.93 1.94 2.08 1.94s2.08-.87 2.08-1.94c0-3.77-3.25-6.83-7.25-6.83-2.84 0-5.44 1.58-6.61 4.03-.39.81-.59 1.76-.59 2.8 0 .78.07 2.01.67 3.61.1.26-.03.55-.29.64-.26.1-.55-.04-.64-.29-.49-1.31-.73-2.61-.73-3.96 0-1.2.23-2.29.68-3.24 1.33-2.79 4.28-4.6 7.51-4.6 4.55 0 8.25 3.51 8.25 7.83 0 1.62-1.38 2.94-3.08 2.94s-3.08-1.32-3.08-2.94c0-1.07-.93-1.94-2.08-1.94s-2.08.87-2.08 1.94c0 1.71.66 3.31 1.87 4.51.95.94 1.86 1.46 3.27 1.85.27.07.42.35.35.61-.05.23-.26.38-.47.38z"/></svg>
              <svg xmlns="http://www.w3.org/2000/svg" className="lbar-icon" alt="my-games" onClick={this.changeToGames} enableBackground="new 0 0 24 24" viewBox="0 0 24 24" fill="black" ><g><rect fill="none" height="24" width="24"/></g><g><g><path d="M16.53,7H7.47C6.48,7,5.63,7.74,5.49,8.72L4.4,16.37c-0.03,0.21,0.05,0.35,0.13,0.44 C4.6,16.9,4.73,17,4.94,17c0.15,0,0.29-0.06,0.39-0.16L8.17,14h7.66l2.84,2.84c0.1,0.1,0.24,0.16,0.39,0.16 c0.21,0,0.34-0.1,0.42-0.19c0.08-0.09,0.16-0.23,0.13-0.44l-1.09-7.66C18.37,7.74,17.52,7,16.53,7z M11,11H9v2H8v-2H6v-1h2V8h1v2 h2V11z M15,10c-0.55,0-1-0.45-1-1c0-0.55,0.45-1,1-1s1,0.45,1,1C16,9.55,15.55,10,15,10z M17,13c-0.55,0-1-0.45-1-1 c0-0.55,0.45-1,1-1s1,0.45,1,1C18,12.55,17.55,13,17,13z" opacity=".3"/><path d="M21.58,16.09l-1.09-7.66C20.21,6.46,18.52,5,16.53,5H7.47C5.48,5,3.79,6.46,3.51,8.43l-1.09,7.66 C2.2,17.63,3.39,19,4.94,19h0c0.68,0,1.32-0.27,1.8-0.75L9,16h6l2.25,2.25c0.48,0.48,1.13,0.75,1.8,0.75h0 C20.61,19,21.8,17.63,21.58,16.09z M19.48,16.81C19.4,16.9,19.27,17,19.06,17c-0.15,0-0.29-0.06-0.39-0.16L15.83,14H8.17 l-2.84,2.84C5.23,16.94,5.09,17,4.94,17c-0.21,0-0.34-0.1-0.42-0.19c-0.08-0.09-0.16-0.23-0.13-0.44l1.09-7.66 C5.63,7.74,6.48,7,7.47,7h9.06c0.99,0,1.84,0.74,1.98,1.72l1.09,7.66C19.63,16.58,19.55,16.72,19.48,16.81z"/><polygon points="9,8 8,8 8,10 6,10 6,11 8,11 8,13 9,13 9,11 11,11 11,10 9,10"/><circle cx="17" cy="12" r="1"/><circle cx="15" cy="9" r="1"/></g></g></svg>
              <svg xmlns="http://www.w3.org/2000/svg" className="lbar-icon" alt="projects" onClick={this.changeToProjects} viewBox="0 0 24 24" fill="black" ><path d="M0 0h24v24H0V0z" fill="none"/><path d="M4 8h16v11H4z" opacity=".3"/><path d="M20 6h-4V4c0-1.11-.89-2-2-2h-4c-1.11 0-2 .89-2 2v2H4c-1.11 0-1.99.89-1.99 2L2 19c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2zM10 4h4v2h-4V4zm10 15H4V8h16v11z"/></svg>
              <svg xmlns="http://www.w3.org/2000/svg" className="lbar-icon" alt="contact" onClick={this.changeToContact} viewBox="0 0 24 24" fill="black" ><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 21.95h5v-2h-5c-4.34 0-8-3.66-8-8s3.66-8 8-8 8 3.66 8 8v1.43c0 .79-.71 1.57-1.5 1.57s-1.5-.78-1.5-1.57v-1.43c0-2.76-2.24-5-5-5s-5 2.24-5 5 2.24 5 5 5c1.38 0 2.64-.56 3.54-1.47.65.89 1.77 1.47 2.96 1.47 1.97 0 3.5-1.6 3.5-3.57v-1.43c0-5.52-4.48-10-10-10s-10 4.48-10 10 4.48 10 10 10zm0-7c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3z" fillOpacity=".9"/></svg>
            </div>      
          </div>
          <div className="mid-container">
            <Profile />
          </div>
          <div className="rbox-container">
            {rbox}
          </div>
        </div>
        <div className="overlay">

        </div>
      </div>  
    );
  }
  
}

export default App;
