import React from "react";
import {projData} from '../../data/proj-data'

 const ProjBox = () => {
  return (
    <>
      <div className="proj-box">
        {projData.map((data, key) => {
          if (data.viewlink) {

          }
          return (
            <div className="p-info-container" key={key} style={{backgroundImage: "url(" + data.imageurl + ")", backgroundSize: "100% 100%"}}>
              <div className="p-tint">
                <h3>{data.title}</h3>
                <p className="p-languages"><i>{data.languages}</i></p>
                <p>{data.description}</p>
                <div className="p-links">
                  <a href={data.github} target="_blank" rel="noopener noreferrer">Github</a>
                  {/* <a href={data.viewlink} rel="noopener noreferrer">View</a> */}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ProjBox;