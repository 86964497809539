import React, { Component } from 'react';
import Pong from './pong'
import './css/ArcadeGames.css';

class ArcadeGame extends Component {
    constructor(props) {
        super(props);
        this.state = { game: 1 }
    }

    render() {
        const game = this.state.game;
        let arcadeGame;
        if (game === 0) {
            arcadeGame = 0; // menu
        } else if (game === 1) {
            arcadeGame = <Pong />;
        }

        return (
            <div className="arcade-game-container">
                {arcadeGame}
            </div>
        );
    }
}

export default ArcadeGame;