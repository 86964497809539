import React from 'react';
import './css/ProjectsPage.css';
import ProjBox from '../display-box/ProjectBox'

function ProjectsPage() {
    return (
        <div className="project-page-container">
            <h1>APPS!</h1>
            <ProjBox />
        </div>
    );
}

export default ProjectsPage