import React from 'react';
import './css/GamePage.css';
import GameBox from '../display-box/GameBox'

function GamesPage() {
    return (
        <div className="game-page-container">
            <h1>GAMES!</h1>
            <GameBox />
        </div>
    );
}

export default GamesPage