export const projData = [
    {
        title: "discoverAR",
        languages: "Android | Java | React",
        description: "discoverAR is an augmented reality app designed for educators! The webapp hosts an editor that allows users to create Journeys while the android app allows users to view them.",
        imageurl: require("../assets/projects/display/discoverAR_logo.png"),
        github: "https://github.com/pbolinao/COMP4800-discoverAR",
        viewlink: ""
    },
    {
        title: "Safe Traveler",
        languages: "Android | Java",
        description: "SafeTraveler is a mobile (android only) navigation app with safety in mind. By tracking your speed and the speed limit the app can help keep you safe! (Currently only works in New Westminster, BC, Canada)",
        imageurl: require("../assets/projects/display/safetraveler_icon_w.png"),
        github: "https://github.com/pbolinao/COMP3717_Term_Project",
        viewlink: ""
    },
    {
        title: "Heart Health Gauge",
        languages: "Android | Java",
        description: "An android app that can be used to track your health! All of your entries are stored in a database and have colour based indicators to display how your health is doing from the results of your entries.",
        imageurl: require("../assets/projects/display/heart_healthline.png"),
        github: "https://github.com/pbolinao/Parekh_Bolinao",
        viewlink: ""
    }
]