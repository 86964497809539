export const gameData = [
    {
        title: "ComposerMON",
        languages: "Javascript | HTML | CSS",
        description: "ComposerMON is a turn based battle game that pits the best composers against one another in a not-so-bloody battle till someone loses!",
        imageurl: require("../assets/game/display/composermon_display.png"),
        github: "https://github.com/pbolinao/ComposerMON",
        playlink: "https://composer-mon.web.app/"
    },
    {
        title: "Memory Game",
        languages: "Javascript | HTML | CSS",
        description: "Inspired by Lumosity's Rotation Matrix game, this game is meant to test your memorization ability while dynamically increasing or decreasing the difficulty as you play!",
        imageurl: require("../assets/game/display/memorygame_display.png"),
        github: "https://github.com/pbolinao/MemoryGame",
        playlink: "https://memorygame-pdb.web.app/"
    },
    {
        title: "ABBA-Lone",
        languages: "Javascript | HTML | CSS",
        description: "With ABBA as the theme, this game of Abalone allows you to play with a friend locally or against an AI!",
        imageurl: require("../assets/game/display/ABBALone-display.png"),
        github: "https://github.com/pbolinao/Abbalone-1",
        playlink: "https://abba-lone.web.app/"
    },
    {
        title: "PONG",
        languages: "React",
        description: "PONG! The ultimate classic. Nothing new, just a good ole fashioned game of pong! The game goes on for forever so you'll never be bored!",
        imageurl: require("../assets/game/display/PONG_display.png"),
        github: "https://github.com/pbolinao/pong",
        playlink: "https://pong-pdb.web.app/"
    }
]