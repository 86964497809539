import React from 'react';
import './css/AboutPage.css';

function AboutPage() {
    return (
        <div className="about-container">
            <h1>About Me</h1>
            <p>
                Hey, my name is Philip Bolinao, I'm a software developer and graduate from the 
                Computer Systems Technology program at BCIT. I currently work at RedMane Technology LLC as a
                Software Developer.
            </p>
            <hr/>
            <h2>Skills</h2>
            <p>Ever since I started down this path of development I have become proficient in many programming languages 
            and frameworks. </p>
            <div className="skills-main">
                <div>
                    <h4>Languages/Frameworks</h4>
                    <ul>
                        <li>Python</li>
                        <li>Java</li>
                        <li>C#</li>
                        <li>C</li>
                        <li>HTML5/CSS3</li>
                        <li>JavaScript</li>
                        <li>React</li>
                        <li>MongoDB</li>
                        <li>PostgreSQL</li>
                        <li>Node.js and Express</li>
                    </ul>
                </div>
                <div>
                    <h4>Technical Skills</h4>
                    <ul>
                        <li>Object Oriented Programming</li>
                        <li>Procedural Programming</li>
                        <li>Artificial Intelligence</li>
                        <li>Machine Learning</li>
                        <li>Android App Development</li>
                        <li>Web App Development</li>
                    </ul>
                </div>
            </div>
            
            <div className="lower-skills">
                <div>
                    <h4>Development Platforms</h4>
                    <ul>
                        <li>Visual Studio Code</li>
                        <li>Visual Studio Professional</li>
                        <li>SQL Server Management Studio</li>
                        <li>MySQL</li>
                        <li>Pycharm</li>
                        <li>Android Studio</li>
                        <li>Intellij IDEA</li>
                        <li>Eclipse</li>
                        <li>Heroku</li>
                        <li>Firebase</li>
                    </ul>
                </div>
            </div>

            {/* <div className="skills-misc">
                <h4>THINK OF ANOTHER THING</h4>
                <ul>
                    <li>:(</li>
                </ul>
            </div> */}

            <hr/>

            <div>
                <h2>Employment</h2>
                <div>
                    <ul>
                        <li>
                            <div className="emp-div">
                                <h4>Software Developer</h4>
                                <p><b>RedMane Technology LLC</b></p>
                                <div className="loc-date">
                                    <p>Burnaby, BC</p>
                                    <div>
                                        <p>Aug. 2021 – Present</p>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    
                </div>
            </div>

            <hr/>

            <div>
                <h2>Education</h2>
                <ul>
                    <li>
                        <div className="edu-div">
                            <h4>Computer Systems Technology (CST) Diploma</h4>
                            <p><b>British Columbia Institute of Technology (BCIT)</b></p>
                            <div className="loc-date">
                                <p>Burnaby, BC</p>
                                <p>Jan. 2019 - Dec. 2020</p>
                            </div>
                            <p><i>Artificial Intelligence and Machine Learning option</i></p>
                            <p><i>Graduated With Distinction</i></p>
                        </div>
                    </li>
                    <li>
                        <div className="edu-div">
                            <h4>Civil Engineering</h4>
                            <p><b>University of Victoria (UVIC)</b></p>
                            <div className="loc-date">
                                <p>Victoria, BC</p>
                                <p>Sep. 2015 - Apr. 2018</p>
                            </div>
                            <p><i>Completed 33.5 credits towards BEng in Civil Engineering</i></p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default AboutPage