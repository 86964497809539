import React from 'react';
import email from '../../assets/link-icons/email.svg';
import location from '../../assets/link-icons/location.svg';
import linkedin from '../../assets/link-icons/linkedin.svg';
import github from '../../assets/link-icons/github.svg';
import './css/ContactPage.css';


function ContactPage() {
    return (
        <div className="contact-container">
            <h1>Contact Me</h1>
            <div className="contact-info">
                <img src={email} alt="email" />
                <p>pdbolinao@gmail.com</p>
            </div>
            <div className="contact-info">
                <img src={location} alt="location" />
                <p>Surrey, BC, Canada</p>
            </div>
            <div className="contact-info">
                <img src={linkedin} alt="linked-in" />
                <a href="https://www.linkedin.com/in/pdbolinao/" target="_blank" rel="noopener noreferrer">Connect with me on LinkedIn!</a>
            </div>
            <div className="contact-info">
                <img src={github} alt="github" />
                <a href="https://github.com/pbolinao" target="_blank" rel="noopener noreferrer">Checkout my GitHub!</a>
            </div>

            {/* <div className="email-me">
                <h2>Send me an email!</h2>
                <form action="/../../scripts/contact-form.php" method="POST" encType="multipart/form-data" autoComplete="off" >
                    <div className="input-container">		
                        <p>Name</p> 
                        <input type="text" required="" id="Name" name="Name" />
                    </div>
                    <div className="input-container">
                        <p>Email</p>
                        <input type="mail" required="" id="Email" name="Email" />
	                </div>
                    <div className="input-container message-container"> 
                        <p>Message</p>
                        <textarea cols="50" rows="5" id="Message" name="Message" ></textarea>
	                </div>
                    <input type="submit" value="Send" id="fcf-button" />
                    <input type="reset" value="Reset"/>
                </form>
            </div> */}
        </div>
    );
}

export default ContactPage